.card {
    width: 1000px;
    margin: 15px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.card.zoomed {
    transform: scale(1.4);
}

.boton {
    display: inline-block;
    width: 130px;
    height: 60px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    outline: none;
}

.boton img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta la imagen para que llene todo el espacio del botón */
}

.vertical-line {
    border-left: 2px solid #000; /* Cambia el color y el grosor según tus necesidades */
    height: 80px; /* Cambia la altura según tus necesidades */
    margin: 0; /* Espaciado horizontal */
}